import * as Yup from "yup";

export const validation = Yup.object().shape({
  competencias: Yup.array()
    .when("estado", {
      is: 2,
      then: Yup.array().of(
        Yup.object().shape({
          nivel: Yup.string().required("Seleccione un nivel"),
          marcadores: Yup.array().min(1, "Seleccione algo"),
        })
      ),
    })
    .when("estado", {
      is: 3,
      then: Yup.array().of(
        Yup.object().shape({
          nivel: Yup.string().required("Seleccione un nivel"),
          marcadores: Yup.array().min(1, "Seleccione algo"),
        })
      ),
    }),
  observacionFinal: Yup.string()
    .required("Campo requerido")
    .max(1800, "No puede exceder los 1800 caracteres")
    .matches(/^[^{}[\]"']*$/, "Por favor no utilice caracteres especiales {}[]\"'"),
  estado: Yup.number()
    .min(1, "Selecciona una opción válida")
    .required("Campo requerido"),
});
