import {
  ButtonProps,
  FlexboxProps,
  FormControl,
  FormErrorMessage,
  FormLabel,
  InputProps,
  StackDirection,
  TextareaProps,
  UseCheckboxGroupProps,
} from "@chakra-ui/react";
import { Field, FieldProps } from "formik";
import { ChangeEventHandler } from "react";
import { InputType } from "./InputType";

type Props = {
  name?: string;
  label?: string;
  labelColor?: string;
  disabled?: boolean;
  inputType:
    | "input"
    | "button"
    | "react-select"
    | "checkbox"
    | "radio"
    | "file"
    | "textarea"
    | "submit";
  options?: { value: string; label: string }[];
  isRequired?: boolean;
  isMulti?: boolean;
  bgInput?: string;
  justifyContent?: FlexboxProps;
  flexDirection?: StackDirection;
  cleanSelector?: string;
  handleChange?: ChangeEventHandler<HTMLInputElement>;
};

export const FormField = ({
  name = "",
  disabled = false,
  label,
  value,
  labelColor,
  isLoading,
  type,
  bgInput,
  isMulti,
  options,
  size,
  colorScheme,
  placeholder,
  isRequired,
  justifyContent,
  inputType,
  flexDirection = "row",
  cleanSelector,
  handleChange,
  ...rest
}: InputProps &
  Props &
  TextareaProps &
  ButtonProps &
  UseCheckboxGroupProps) => {
  return (
    <Field name={name}>
      {({ field, form }: FieldProps) => {
        return (
          <FormControl
            isInvalid={Boolean(form.errors[name] && form.touched[name])}
            isRequired={isRequired}
          >
            {label && (
              <FormLabel
                htmlFor={name}
                color={labelColor}
                fontSize="sm"
                height="15px"
              >
                {label}
              </FormLabel>
            )}

            <InputType
              field={field}
              id={name}
              placeholder={placeholder}
              type={type}
              inputType={inputType}
              isLoading={isLoading}
              bgInput={bgInput}
              colorScheme={colorScheme}
              size={size}
              disabled={disabled}
              options={options}
              isMulti={isMulti}
              formFormik={form}
              justifyContent={justifyContent}
              flexDirection={flexDirection}
              cleanSelector={cleanSelector}
              handleChange={handleChange}
              onBlur={field.onBlur}
              {...rest}
            />
            <FormErrorMessage>{`${form.touched[name] && form.errors[name] ? form.errors[name] : ""}`}</FormErrorMessage>
          </FormControl>
        );
      }}
    </Field>
  );
};
